import { useAppSelector } from "@/hooks";
import { useGetArticlesQuery } from "@/services/bmApi";
import React from "react";
import Article from "./Article";

const Articles = () => {
  const configuration = useAppSelector((state) => state.configuration.data);
  const currency = useAppSelector((state) => state.articles.currency);

  const { data: articles } = useGetArticlesQuery({
    CompanyId: configuration.company,
    // @ts-ignore
    Active: true
  });
  
  return articles && articles?.Results ? (
    <>
      {articles?.Results
      // TODO: show standalone type (2) and service type (1) 
      // when the functionality is 100% there
      ?.filter(article => article.ArticleTypeId !== 1)
      ?.filter(article => article.ArticleTypeId !== 2)
      ?.map((article) => (
        <Article
          article={article}
          // @ts-ignore
          currency={article.PriceSign}
        />
      ))}
    </>
  ) : null;
};

export default Articles;
