import { createReducer } from 'typesafe-actions';
import { PromoCode } from '@/types';

const initialState = {
    isLoading: false,
    hasError: false,
    error: null,
    data: [],
    requestedCode: '',
    inputShown: false,
};

interface State {
    isLoading: boolean;
    hasError: boolean;
    error: null | any;
    data: PromoCode[];
    requestedCode: string;
    inputShown: boolean;
}

const reducer = createReducer<State>(initialState, {
    SELECT_SERVICE: () => initialState,
    SELECT_TIME: () => initialState,
    APPLY_PROMO_CODE_REQUEST: (state, action) => ({
        ...state,
        isLoading: true,
        data: state.data,
        requestedCode: action.payload?.RebateCodeSign,
        hasError: false,
        error: null,
    }),
    APPLY_PROMO_CODE_SUCCESS: (state, action) => ({
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
        hasError: false,
        error: null,
    }),
    APPLY_PROMO_CODE_FAILURE: (state, action) => ({
        ...state,
        isLoading: false,
        data: [],
        hasError: true,
        error: action.payload,
    }),
    SHOW_PROMO_CODE_INPUT: (state, action) => ({
        ...state,
        inputShown: true,
    }),
    HIDE_PROMO_CODE_INPUT: (state, action) => ({
        ...state,
        inputShown: false,
        error: null,
    }),
});

export default reducer;
