import ServicesAPI, { CreateCheckout } from "@/api/services";
import { definitions } from "@/apitypes";
import { createArticlePaymentUrls, getCountryCode } from "@/misc/common";
import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { TypedListener } from "@/store";
import { Article, Currency, CheckoutResponse, RebateCode } from "@/types";
import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
  PayloadAction,
  createAction,
} from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { getFormValues, setSubmitFailed } from "redux-form";

interface InitialState {
  entity: RebateCode | Article | null;
  selected: Article | null;
  isLoading: boolean;
  checkout: {
    isLoading: boolean;
    error: any;
    entity: CheckoutResponse | null; 
  };
  error: any;
}

export const initialState: InitialState = {
  entity: null,
  selected: null,
  error: null,
  isLoading: false,
  checkout: {
    isLoading: false,
    error: null,
    entity: null,
  },
};

// Middlewares
export const articleListener = createListenerMiddleware();
const startArticleListening = articleListener.startListening as TypedListener;

// Actions

export const confirmArticle = createAction(
  "article/confirm",
  (article: Article) => ({ payload: article })
);

// Slice

const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    selectArticle: (
      state,
      { payload }: PayloadAction<{ article: Article }>
    ) => {
      state.selected = payload.article;
      state.error = undefined;
      state.isLoading = false;
    },
    createRebateCodeSuccess: (state, action: PayloadAction<RebateCode>) => {
      state.entity = action.payload;
    },
    createRebateCodeFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    buyArticleRequest: (state) => {
      state.checkout.isLoading = true;
      state.checkout.error = undefined;
    },
    buyArticleSuccess: (
      state,
      action: PayloadAction<definitions["QvicklyCheckoutResponse"]>
    ) => {
      state.checkout.entity = action.payload;
      state.checkout.isLoading = false;
      state.checkout.error = undefined;
    },
    buyArticleFail: (state, action) => {
      state.checkout.error = action.payload;
      state.checkout.isLoading = false;
      
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(confirmArticle, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
  },
});

export const {
  selectArticle,
  createRebateCodeFailure,
  buyArticleSuccess,
  buyArticleFail,
  createRebateCodeSuccess,
} = articleSlice.actions;

// Listeners

startArticleListening({
  matcher: isAnyOf(selectArticle.match),
  effect: async ({ payload }: ReturnType<typeof selectArticle>, api) => {
    api.dispatch(push(`/articles/${payload.article.Id}/confirm`));
  },
});

startArticleListening({
  predicate: confirmArticle.match,
  effect: async ({ payload }, api) => {
    let rebateCode;
    const company = api.getState().company.data;
    const configuration = api.getState().configuration.data;
    const customerForm = getFormValues("customerForm")(api.getState());
    const recipientForm: any = getFormValues("recipientForm")(api.getState());
    
    try {
      rebateCode = await ServicesAPI.createRebateFromArticle({
        ArticleId: payload.Id,
        CompanyId: company?.Id,
        ...customerForm,
        ...(recipientForm?.Email ? { Receiver: recipientForm } : {})
      });

      api.dispatch(createRebateCodeSuccess(rebateCode));
    } catch (error) {
      api.dispatch(createRebateCodeFailure(error));
      api.dispatch(setSubmitFailed("createArticleForm"));
    }

    try {
      if (company?.Id && company.BookingSettings?.PaymentProviderId && rebateCode) {
        const { checkoutUrl, confirmationUrl } = createArticlePaymentUrls(
          configuration,
          payload
        );

        const checkoutPayload: CreateCheckout = {
          CompanyId: company.Id,
          Id: rebateCode.Id,
          CheckoutUrl: checkoutUrl.href,
          ConfirmationUrl: confirmationUrl.href,
          PaymentProviderId: company.BookingSettings?.PaymentProviderId,
          CountryCode: getCountryCode(
            readConfigurationProperty("language", "se")
          ),
          Articles: [
            {
              ArticleTypeId: payload.ArticleTypeId,
              ArticleId: payload.Id,
              Quantity: 1,
            },
          ],
        };

        const checkoutResponse = await ServicesAPI.createCheckout(
          checkoutPayload
        );

        const articleState = api.getState().article;

        api.dispatch(buyArticleSuccess(checkoutResponse));
        api.dispatch(push(`/articles/${articleState?.selected?.Id}/completed`));
      }
    } catch (error: any) {
      const articleState = api.getState().article;
      
      api.dispatch(setSubmitFailed("createArticleForm"));
      api.dispatch(buyArticleFail(error));
      api.dispatch(push(`/articles/${articleState?.selected?.Id}/completed`));
    }
  },
});

export default articleSlice;
